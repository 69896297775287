import React, {useCallback} from 'react';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

import {Order} from '../../../../../../../client/openapitools/marketplace/api';
import BSCCardBox from '../../../../../../../features/common/bscCardBox/bscCardBox';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import BSCDivider from '../../../../../../../features/common/bscDivider/bscDivider';
import BSCCurrency from '../../../../../../../features/common/bscCurrency/bscCurrency';
import {useHistory, useRouteMatch} from 'react-router';
import BSCHeaderBlock from '../../../../../../../features/common/bscHeaderBlock/bscHeaderBlock';
import useDateNormalizer from '../../../../../../../client/common/normalizer/dateTimeNormalizer';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: '#F5F8FA',
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: '8px',
        padding: theme.spacing(0),
        '& > MuiSelect-icon': {
            color: '#EA3E2E !important',
        },
        paddingBottom: 4,
    },
    item: {
        padding: theme.spacing(1, 2),
    },
    orderNo: {
        color: '#EF2424',
        textAlign: 'right',
        cursor: 'pointer',

        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.subtitle2.fontSize,
        },
    },
    value: {
        textAlign: 'right',

        // [theme.breakpoints.down('sm')]: {
        //     fontSize: theme.typography.subtitle2.fontSize,
        // },
    },
    divider: {
        margin: 0,
    },
}));

interface OrderDetailsProps {
    order: Order;
    orderUrl?: string;
    hasTitle?: boolean;
}

function OrderDetails({order, orderUrl = '/dashboard/orderHistory', hasTitle = true}: OrderDetailsProps) {
    const NOT_SHIPPED = 'Not Shipped';
    const classes = useStyles();
    const history = useHistory();
    const handleClick = useCallback(() => {
        history.push({
            pathname: orderUrl,
            state: {orderId: order.orderId, orderNumber: order.orderNo},
        });
    }, [history, order, orderUrl]);
    const dateNormalizer = useDateNormalizer();
    const isSellers = useRouteMatch('/sellers');

    return (
        <>
            <BSCCardBox className={classes.root}>
                {hasTitle && <BSCHeaderBlock label="Order Details" />}
                <Grid container justifyContent="space-between" alignItems="center" className={classes.item}>
                    <Grid item xs={4}>
                        <BSCTypography label="Order #:" />
                    </Grid>
                    <Grid item xs={8}>
                        <BSCTypography label={order?.orderNo} className={classes.orderNo} onClick={handleClick} />
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between" alignItems="center" className={classes.item}>
                    <Grid item xs={4}>
                        <BSCTypography label="Seller Name:" />
                    </Grid>
                    <Grid item xs={8}>
                        <BSCTypography label={order?.seller?.sellerProfile?.sellerStoreName} className={classes.value} />
                    </Grid>
                </Grid>
                {order?.buyer?.firstName && order?.buyer?.lastName && (
                    <Grid container justifyContent="space-between" alignItems="center" className={classes.item}>
                        <Grid item xs={4}>
                            <BSCTypography label="Buyer Name:" />
                        </Grid>
                        <Grid item xs={8}>
                            <BSCTypography label={`${order?.buyer?.firstName} ${order?.buyer?.lastName}`} className={classes.value} />
                        </Grid>
                    </Grid>
                )}
                <Grid container justifyContent="space-between" alignItems="center" className={classes.item}>
                    <Grid item xs={4}>
                        <BSCTypography label="Order Date:" />
                    </Grid>
                    <Grid item xs={8}>
                        <BSCTypography label={dateNormalizer.getDisplayDate(order?.orderDate)} className={classes.value} />
                    </Grid>
                </Grid>
                {order?.orderStatus === 'SHIPPED' && (
                    <Grid container justifyContent="space-between" alignItems="center" className={classes.item}>
                        <Grid item xs={4}>
                            <BSCTypography label="Date Shipped:" />
                        </Grid>
                        <Grid item xs={8}>
                            <BSCTypography
                                label={order?.shipments ? dateNormalizer.getDisplayDate(order?.shipments[0]?.shippedDate) : NOT_SHIPPED}
                                className={classes.value}
                            />
                        </Grid>
                    </Grid>
                )}
                {order?.orderStatus !== 'SHIPPED' && (
                    <Grid container justifyContent="space-between" alignItems="center" className={classes.item}>
                        <Grid item xs={4}>
                            <BSCTypography label="Status:" />
                        </Grid>
                        <Grid item xs={8}>
                            <BSCTypography capitalize label={`${order?.orderStatus.toLowerCase()}`} className={classes.value} />
                        </Grid>
                    </Grid>
                )}
                <BSCDivider className={classes.divider} />
                <Grid container justifyContent="space-between" alignItems="center" className={classes.item}>
                    <Grid item xs={4}>
                        <BSCTypography label="Sub Total:" />
                    </Grid>
                    <Grid item xs={8}>
                        <BSCCurrency amount={order?.subTotal} className={classes.value} />
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between" alignItems="center" className={classes.item}>
                    <Grid item xs={4}>
                        <BSCTypography label="Shipping:" />
                    </Grid>
                    <Grid item xs={8}>
                        <BSCCurrency amount={order?.shippingCost} className={classes.value} />
                    </Grid>
                </Grid>
                {!isSellers && (
                    <Grid container justifyContent="space-between" alignItems="center" className={classes.item}>
                        <Grid item xs={4}>
                            <BSCTypography label="Sales Tax:" />
                        </Grid>
                        <Grid item xs={8}>
                            <BSCCurrency amount={order?.salesTax} className={classes.value} />
                        </Grid>
                    </Grid>
                )}
                <BSCDivider className={classes.divider} />
                <Grid container justifyContent="space-between" alignItems="center" className={classes.item}>
                    <Grid item xs={4}>
                        <BSCTypography label="Total:" />
                    </Grid>
                    <Grid item xs={8}>
                        <BSCCurrency amount={isSellers ? order?.orderSubtotal : order?.total} className={classes.value} />
                    </Grid>
                </Grid>
            </BSCCardBox>
        </>
    );
}

export default OrderDetails;
