import {toInteger} from 'lodash';

export const getNameList = (attachedFiles: File[]) => {
    let nameList = [];
    for (let i = 0; i < attachedFiles.length; i++) {
        nameList = [...nameList, attachedFiles[i].name];
    }
    return nameList;
};

export const removeFile = (name, attachedFiles: File[]) => {
    if (attachedFiles) {
        const filteredFileList = attachedFiles.filter(file => file.name !== name);
        return filteredFileList.length === 0 ? null : filteredFileList;
    }
    return null;
};

export const getPageNum = (total: number, sizePerPage: number) => {
    let pageNum = toInteger(total / sizePerPage);
    pageNum = total % sizePerPage > 0 ? pageNum + 1 : pageNum;
    return pageNum;
};

export const getFormatedDate = (date: string) => {
    return date?.replace(' ', ' - ') ?? null;
};

export const numberWithCommas = (x: number): string => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
};

export const maxCharacter = (string, maxCharacters = 14, showValueAfter = '') => {
    if (!maxCharacters) {
        return string;
    }

    if (!showValueAfter) {
        if (string && string.length > maxCharacters) {
            return `${string.slice(0, maxCharacters)}...`;
        }
    } else {
        if (string.length > maxCharacters) {
            const idx = string.indexOf(showValueAfter);

            const display = string.substring(idx);
            return `${string.slice(0, maxCharacters - display.length)}...${display}`;
        }
    }

    return string;
};

export const getFormatedNumberWithK = (num: number) =>
    Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k' : currencyFormatter(Math.sign(num) * Math.abs(num));

export const getFormattedNumberWithK = (num: number) =>
    (Math.abs(num) / 1000).toFixed(1).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1') + 'k';

export const getFormattedNumberWithM = (num: number) =>
    (Math.abs(num) / 1000000).toFixed(1).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1') + 'M';

export const currencyFormatter = value => {
    if (value === undefined) {
        return '';
    }
    if (isNaN(Number(value))) {
        if (!Number.isNaN(value) && value.indexOf(',') > -1) {
            value = value.replace(',', '');
        } else {
            return '';
        }

        if (!Number(value)) {
            return '';
        }
    }

    const amount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value);
    // temporarily removing '$' since form validator rejects as invalid
    return amount.substr(1, amount.length);
    // return amount;
};

export type Falsy = false | 0 | '' | null | undefined;

// this is a type predicate - if x is `truthy`, then it's T
export const isTruthy = <T>(x: T | Falsy): x is T => !!x;
