import {Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import * as constants from '../../../../shared/constants';
import './styles.scss';
import {useMsal} from '@azure/msal-react';
import {OfferApi as CommonOfferApi} from '../../../../client/openapitools/common/api';
import {AccountInfo, Configuration, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {OrderApi} from '../../../../client/openapitools/seller/api';
import {useAuthState} from '../../../../AuthContext';

const useStyles = makeStyles((theme: Theme) => ({
    selectionsWrapper: {
        padding: '8px 8px 8px 6px !important',
    },
    selectionWrapper: {
        backgroundColor: theme.palette.common.white,
        padding: 4,
        marginBottom: 6,
    },
    largeIcon: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        overflow: 'hidden',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '50%',
        width: 47,
        height: 35,
        paddingTop: 11,
    },
}));

const READY_TO_SHIP = 'READY_TO_SHIP';
const defaultOrderSearch = {
    name: '',
    orderNo: '',
    fromDate: null,
    toDate: null,
    page: 0,
    size: 100,
    status: [],
};

function PWASellerDashboard() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();

    const handleDashSelection = (index: number) => {
        switch (index) {
            case 0:
                history.push('/sellers/dashboard/profile');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 1,
                    },
                });
                break;
            case 1:
                history.push('/sellers/inventory/inventory?myInventory=false');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 2,
                    },
                });
                break;
            case 2:
                history.push('/sellers/inventory/inventory?myInventory=true');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 3,
                    },
                });
                break;
            case 3:
                history.push('/sellers/orders');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 4,
                    },
                });
                break;
            case 4:
                history.push('/sellers/offers');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 5,
                    },
                });
                break;
            case 5:
                history.push('/sellers/sales-summary');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 6,
                    },
                });
                break;
            case 6:
                history.push('/sellers/payments');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 7,
                    },
                });
                break;
            case 7:
                history.push('/sellers/dashboard/feedback');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 8,
                    },
                });
                break;
            case 8:
                history.push('/sellers/dashboard/faq');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 9,
                    },
                });
                break;
            case 9:
                history.push('/sellers/dashboard/support');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 10,
                    },
                });
                break;
            default:
                break;
        }
    };

    const [unshippedOrdersCount, setUnshippedOrdersCount] = useState('');
    const searchOrders = async searchObj => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const orderApi = new OrderApi(apiConfig);
                            const response = await orderApi.getOrderHistory(searchObj);
                            const unshippedCount = response.data.results.filter(order => order.orderStatus === READY_TO_SHIP).length;
                            if (unshippedCount > 0) {
                                setUnshippedOrdersCount(unshippedCount.toString());
                                dispatch({
                                    type: constants.PWA_SELLER_ORDERS_COUNT,
                                    payload: {pwaSellerOrdersCount: unshippedCount},
                                });
                            }
                        } catch (error) {
                            console.error('Error fetching orders', error);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    console.error('Error fetching orders', error);
                });
        }
    };

    const [outstandingOffersCount, setOutstandingOffersCount] = useState('');
    const searchOffers = async () => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const commonOfferApi = new CommonOfferApi(apiConfig);
                            const offerResponse = await commonOfferApi.getSellerOfferHistory();
                            const outstandingOffersQty = offerResponse.data.history.filter(
                                offer => offer.offerStatus !== 'Accepted' && offer.offerStatus !== 'Rejected'
                            ).length;
                            if (outstandingOffersQty > 0) {
                                setOutstandingOffersCount(outstandingOffersQty.toString());
                                dispatch({
                                    type: constants.PWA_SELLER_OFFERS_COUNT,
                                    payload: {pwaSellerOffersCount: outstandingOffersQty},
                                });
                            }
                        } catch (error) {
                            console.error('Error fetching orders', error);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    console.error('Error fetching orders', error);
                });
        }
    };

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'pageview',
            page: {
                path: location.pathname,
                search: location.search,
                hash: location.hash,
            },
        });
    }, [location]);

    useEffect(() => {
        dispatch({
            type: constants.PWA_NAVBAR_ACTIVE_TAB,
            payload: {
                pwaNavbarActive: 4,
            },
        });
        dispatch({
            type: constants.PWA_BUYER_DASHBOARD_SELECTION,
            payload: {
                pwaBuyerDashboardSelection: 0,
            },
        });
        searchOrders(defaultOrderSearch);
        searchOffers();
    }, []);

    return (
        <div className={classes.selectionsWrapper}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container direction="row">
                        <Grid item xs={12} onClick={() => handleDashSelection(0)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">person</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Seller Profile
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(1)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">playing_cards</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Sell Your Cards
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(2)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">inventory_2</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        My Inventory
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(3)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">paid</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    {unshippedOrdersCount === '' && <BSCTypography size18 style={{fontWeight: 600}} label="Orders" />}
                                    {unshippedOrdersCount !== '' && (
                                        <BSCTypography size18 style={{fontWeight: 600}} label={`Orders (${unshippedOrdersCount})`} />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(4)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">currency_exchange</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    {outstandingOffersCount === '' && <BSCTypography size18 style={{fontWeight: 600}} label="Offers" />}
                                    {outstandingOffersCount !== '' && (
                                        <BSCTypography size18 style={{fontWeight: 600}} label={`Offers (${outstandingOffersCount})`} />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(5)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">summarize</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Sales Summary
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(6)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">payments</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Payments
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(7)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">rate_review</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Feedback
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(8)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">question_mark</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        FAQs
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} onClick={() => handleDashSelection(9)}>
                            <Grid container alignItems="center" className={classes.selectionWrapper}>
                                <Grid item container xs={2} justifyContent="center">
                                    <div className={classes.largeIcon}>
                                        <span className="material-symbols-outlined">contact_support</span>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <BSCTypography size18 style={{fontWeight: 600}}>
                                        Contact Support
                                    </BSCTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default PWASellerDashboard;
