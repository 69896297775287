/* eslint-disable */
import Box from '@material-ui/core/Box';
import {makeStyles, Theme} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import SearchShopResultsClient from '../../../../client/search/shop/results/searchShopResultsClient';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCHidingHeaderBar from '../../../../features/common/bscHidingHeaderBar/bscHidingHeaderBar';
import BSCPagination from '../../../../features/common/bscPagination/bscPagination';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';
import {useQuery} from '../../../../packages/core/src/hooks/useQuery';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import useSearch from '../../../../packages/core/src/hooks/useSearch';
import {RootState} from '../../../../redux/reducers';
import {clearSearch} from '../../../../redux/slices/marketplaceSearch.slice';
import * as constants from '../../../../shared/constants';
// import PWASearchSortComponent from '../pwa/pwaSearchSortComponent';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {Slide} from '@material-ui/core';
import {TransitionProps} from '@material-ui/core/transitions';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthState} from '../../../../AuthContext';
import {RecentSearchControllerApi} from '../../../../client/openapitools/common/api';
import {UserApi} from '../../../../client/openapitools/marketplace/api';
import useInfiniteSearch from '../../../../packages/core/src/hooks/useInfiniteSearch';
import {searchCreator} from '../../../../util/searchCreator';
import PWASearchSortComponent from '../pwa/pwaSearchSortComponent';
import SearchMenuHeader from './SearchMenuHeader';
import SearchResults from './SearchResults';
import SearchSidebar from './sidebar/SearchSidebar';
const queryString = require('query-string');

const useStyles = makeStyles((theme: Theme) => ({
    searchPageContainer: {
        //padding: theme.spacing(1, 3),
        //overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        flexDirection: 'row',
        maxWidth: '99%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '99%',
        },
        // margin: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
    },
    pwaSearchPageContainer: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        flexDirection: 'row',
        maxWidth: '99%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '99%',
        },
        display: 'flex',
        justifyContent: 'space-between',
        height: 'calc(100vh - 256px)',
        overflowY: 'scroll',
        position: 'relative',
        top: 4,
        // border: '1px solid #00f',
    },
    pwaSearchPageStorefrontContainer: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        flexDirection: 'row',
        maxWidth: '99%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '99%',
        },
        display: 'flex',
        justifyContent: 'space-between',
        height: 'calc(100vh - 310px)',
        overflowY: 'scroll',
        // border: '1px solid #00f',
    },
    drawerPaper: {
        width: '80%',
    },
    drawerPaperPWA: {
        top: 60,
        width: '80%',
    },
    searchBar: {
        padding: theme.spacing(1, 2),
        position: 'sticky',
        top: 140,
        flex: 3,
        maxWidth: 300,
        alignSelf: 'flex-start',
        height: 'auto',
        [theme.breakpoints.down('lg')]: {
            maxWidth: 300,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            display: 'none',
        },
        verticalAlign: 'top',
    },
    searchResults: {
        [theme.breakpoints.down('sm')]: {
            // paddingLeft: theme.spacing(2),
            // paddingRight: theme.spacing(2),
            marginLeft: 0,
            display: 'block',
        },
    },
    searchWrap: {
        //display: 'inline-block',
    },
    searchPageCol: {
        paddingTop: theme.spacing(1),
        flexDirection: 'column',
        flex: 4,
    },
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {children?: React.ReactElement<undefined>},
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SearchPage() {
    const classes = useStyles();
    const {search} = useLocation();
    const {apiAuthConfig} = useAuthState();
    const {getCartSessionId, sessionId} = useReduxUser();
    const sellerId = new URLSearchParams(search).get('sellerId');
    const sellerName = new URLSearchParams(search).get('sellerName');
    useEffect(() => {
        if (sellerId && sellerName) {
            dispatch({
                type: constants.SELLER_STOREFRONT_SELECTED,
                payload: {sellerId: sellerId, sellerName: sellerName},
            });
        }
    }, [sellerId, sellerName]);
    const {isMobile, isTablet, isBelowTablet, isDesktop} = useScreenSnap();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {
        cleanedSearchQuery,
        handleCheckboxChange,
        handleClearAll,
        handlePaging,
        handleParamDelete,
        handleSortChange,
        handleRadioChange,
        handleStockToggleChange,
        handleConditionsChange,
        handlePriceRangeChange,
    } = useSearch();
    const {sort, p, inStock, condition} = cleanedSearchQuery;
    const [drawerOpen, setDrawerOpen] = useState(false);
    const dispatch = useDispatch();
    const belowTablet = isTablet || isMobile;
    const [selectedSellerId, setSelectedSellerId] = useState(null);
    const [sessionInStock, setSessionInStock] = useState(false);
    const [pwaOS, setPwaOS] = useState(null);
    const {
        loading,
        error: errors,
        data: marketPlaceResults,
    } = useQuery(() => {
        return SearchShopResultsClient.marketPlaceSearch({
            terms: cleanedSearchQuery,
            sellerKey: selectedSellerId,
            resultsPerPage: pwaOS !== null ? 50 : 48,
        });
    }, [cleanedSearchQuery, selectedSellerId]);
    const aggregations = marketPlaceResults?.data?.aggregations;
    const searchResults = marketPlaceResults?.data?.results;
    let appliedFilters = marketPlaceResults?.data?.appliedFilters;
    const totalResults = marketPlaceResults?.data?.totalResults;
    const aggregatedValues = aggregations ?? null;
    const aggregatedKeys = aggregations && Object.keys(aggregations);
    const {instance} = useMsal();

    const [pageNumber, setPageNumber] = useState(0);
    const [isInfiniteSearchLoading, setIsInfiniteSearchLoading] = useState(false);
    const {infiniteResults, infiniteResultsTotal} = useInfiniteSearch(pageNumber);

    const handleCloseDrawer = useCallback(() => {
        setDrawerOpen(false);
    }, []);

    const [checked, setChecked] = useState(false);
    const handlePWASortOptions = () => {
        setChecked(prev => !prev);
    };

    const [mobileFilterCount, setMobileFilterCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    useEffect(() => {
        if (cleanedSearchQuery.p) setCurrentPage(parseInt(cleanedSearchQuery.p));
    }, [cleanedSearchQuery.p]);

    useEffect(() => {
        if (cleanedSearchQuery.q) appliedFilters = null;
    }, [cleanedSearchQuery.q]);

    useEffect(() => {
        let filterCount = 0;
        if (cleanedSearchQuery) {
            const {
                sort,
                inStock,
                rawCondition,
                gradingCompany,
                letterGrade,
                gradeRangeStart,
                gradeRangeEnd,
                priceRangeStart,
                priceRangeEnd,
            } = cleanedSearchQuery;
            if (rawCondition) {
                filterCount += rawCondition.length;
            }
            if (gradingCompany) {
                filterCount += gradingCompany.length;
            }
            if (letterGrade) {
                filterCount += letterGrade.length;
            }
            if (gradeRangeStart) {
                filterCount++;
            }
            if (gradeRangeEnd) {
                filterCount++;
            }
            if (priceRangeStart || priceRangeEnd) {
                filterCount++;
            }
            dispatch({
                type: constants.BREADCRUMBS_CARD_CONDITION,
                payload: {breadcrumbsCardCondition: condition},
            });
            dispatch({
                type: constants.BREADCRUMBS_IN_STOCK,
                payload: {breadcrumbsInStock: inStock === 'true' ? true : false},
            });
            dispatch({
                type: constants.BREADCRUMBS_SORT_ORDER,
                payload: {breadcrumbsSortOrder: sort},
            });
        }
        if (appliedFilters && appliedFilters.length > 0 && cleanedSearchQuery.q) {
            for (let i = 0; i < appliedFilters.length; i++) {
                if (!cleanedSearchQuery[appliedFilters[i].type]) {
                    cleanedSearchQuery[appliedFilters[i].type] = [];
                }
                cleanedSearchQuery[appliedFilters[i].type].push(appliedFilters[i].slug);
            }
            cleanedSearchQuery.q = null;
            cleanedSearchQuery.inStock = sessionInStock;
            cleanedSearchQuery.condition = condition ?? 'all';
        }
        if (appliedFilters && appliedFilters.length > 0) {
            filterCount += appliedFilters.length;
        }
        setMobileFilterCount(filterCount);
    }, [cleanedSearchQuery, appliedFilters]);

    const saveRecentSearch = async recentSearchObject => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const recentSearchApi = new RecentSearchControllerApi(apiConfig);
                            const recentSearchResults = await recentSearchApi.addRecentSearch(recentSearchObject);
                        } catch (error) {
                            console.log('error', error);
                        }
                    }
                })
                .catch(error => {
                    console.error('Error fetching user', error);
                });
        } else {
            try {
                const recentSearchApi = new RecentSearchControllerApi();
                const recentSearchResults = await recentSearchApi.addRecentSearch(recentSearchObject);
                // if (recentSearchResults) {
                //     console.log('>>> recentSearchResults', recentSearchResults.data);
                // }
            } catch (error) {
                console.log('error', error);
            }
        }
    };

    const [userId, setUserId] = useState(null);
    useEffect(() => {
        if (marketPlaceResults?.data?.results?.length > 0) {
            const sessionId = getCartSessionId();
            const {postObj} = searchCreator({
                terms: cleanedSearchQuery,
                listingSearch: {},
                sellerKey: selectedSellerId ?? null,
            });
            const url = new URL(window.location.href);
            url.searchParams.delete('p');
            postObj['query'] = url.search;
            saveRecentSearch({sessionId, userId, criteria: postObj});
        }
    }, [cleanedSearchQuery, marketPlaceResults]);

    const [hasMoreInfiniteResults, setHasMoreInfiniteResults] = useState(false);
    useEffect(() => {
        const sessionId = getCartSessionId();
        const {postObj} = searchCreator({
            terms: cleanedSearchQuery,
            listingSearch: {},
            sellerKey: selectedSellerId ?? null,
        });
        const url = new URL(window.location.href);
        url.searchParams.delete('p');
        postObj['query'] = url.search;
        if (infiniteResults.length < infiniteResultsTotal) {
            console.log('>>> there are more results');
            setHasMoreInfiniteResults(true);
        } else {
            console.log('>>> there are NO more results');
            setHasMoreInfiniteResults(false);
        }
        saveRecentSearch({sessionId, userId, criteria: postObj});
        setIsInfiniteSearchLoading(false);
    }, [infiniteResults, infiniteResultsTotal]);

    const [isStorefrontPresent, setIsStorefrontPresent] = useState(false);
    useEffect(() => {
        if (marketplaceState) {
            const tempSellerId = marketplaceState.sellerStorefront !== {} ? marketplaceState.sellerStorefront['sellerId'] : null;
            setSelectedSellerId(tempSellerId);
            setSessionInStock(marketplaceState.sessionInStock);
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
            if (marketplaceState.sellerStorefront['sellerId'] !== undefined) {
                setIsStorefrontPresent(true);
            }
        }
    }, [marketplaceState]);

    const handleOpenDrawer = useCallback(() => {
        setDrawerOpen(true);
    }, []);

    const handlePageChange = (evt, paginationPageNumber) => {
        if (isMobile && marketplaceState.scrollableId !== '') {
            dispatch({
                type: constants.SET_MARKETPLACE_SCROLLABLE_ID,
                payload: {scrollableId: ''},
            });
        }
        if (pwaOS !== null) {
            handlePaging(null, currentPage + 1);
        } else {
            if (paginationPageNumber > 0) {
                handlePaging(null, paginationPageNumber - 1);
            }
        }
    };

    const handleBottomDetected = () => {
        setIsInfiniteSearchLoading(true);
        setPageNumber(pageNumber + 1);
    };

    const onClearAll = () => {
        handleClearAll();
        dispatch(clearSearch());
        window.scrollTo(0, 0);
    };

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [sellerProfile, setSellerProfile] = useState(null);
    useEffect(() => {
        const parsed = queryString.parse(window.location.search);
        let titleSport = parsed['sport[]'];
        if (titleSport) {
            titleSport = titleSport.replace(/\b\w/g, l => l.toUpperCase());
            if (titleSport === 'Mma') {
                titleSport = 'MMA';
            }
            document.title = `BuySportsCards: Shop ${titleSport} Cards`;
        } else {
            document.title = 'BuySportsCards.com: Millions of Sports Cards for Sale';
        }
        if (marketplaceState.pwaSettings.pwaOS !== '') {
            dispatch({
                type: constants.PWA_NAVBAR_ACTIVE_TAB,
                payload: {
                    pwaNavbarActive: 2,
                },
            });
        }
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const userApi = new UserApi(apiConfig);
                            const response = await userApi.getUserProfile();
                            const tempUserId = response.data.id;
                            setUserId(tempUserId);
                            setSellerProfile(response.data.sellerProfile);
                        } catch (error) {}
                    }
                })
                .catch(error => {
                    console.error('Error fetching user', error);
                });
        }
    }, []);

    return (
        <>
            <div>
                {isMobile && (
                    <BSCHidingHeaderBar relativePositionThreshold={125} disapearingThreshold={140}>
                        <SearchMenuHeader
                            loading={loading}
                            filterCount={mobileFilterCount}
                            totalResults={totalResults || 0}
                            selectedSortValue={sort || 'best-sellers'}
                            sellerProfile={sellerProfile}
                            onSortChange={handleSortChange}
                            onOpenDrawer={handleOpenDrawer}
                            onPWASortOptions={handleClickOpen}
                        />
                    </BSCHidingHeaderBar>
                )}
                {!isMobile && (
                    <SearchMenuHeader
                        loading={loading}
                        filterCount={appliedFilters?.length ?? 0}
                        totalResults={totalResults || 0}
                        selectedSortValue={sort || 'best-sellers'}
                        sellerProfile={sellerProfile}
                        onSortChange={handleSortChange}
                        onOpenDrawer={handleOpenDrawer}
                    />
                )}
                <div
                    className={
                        pwaOS === null
                            ? classes.searchPageContainer
                            : isStorefrontPresent
                              ? classes.pwaSearchPageStorefrontContainer
                              : classes.pwaSearchPageContainer
                    }
                >
                    <Box className={classes.searchBar}>
                        {belowTablet ? (
                            <SwipeableDrawer
                                anchor={pwaOS !== null ? 'right' : 'left'}
                                open={drawerOpen}
                                onClose={handleCloseDrawer}
                                onOpen={handleOpenDrawer}
                                classes={{paper: pwaOS !== null ? classes.drawerPaperPWA : classes.drawerPaper}}
                            >
                                <SearchSidebar
                                    isMobile={belowTablet}
                                    loading={loading}
                                    errors={errors}
                                    aggregatedKeys={aggregatedKeys}
                                    aggregatedValues={aggregatedValues}
                                    parsedQueryResults={cleanedSearchQuery}
                                    chipList={appliedFilters}
                                    onRadioChange={handleRadioChange}
                                    onCheckboxChange={handleCheckboxChange}
                                    onClearAll={onClearAll}
                                    onStockToggleChange={handleStockToggleChange}
                                    onConditionsChange={handleConditionsChange}
                                    onPriceRangeChange={handlePriceRangeChange}
                                    onParamDelete={handleParamDelete}
                                    showFindASeller={true}
                                    inStockToggle={sessionInStock?.toString()}
                                    showInStock={true}
                                    showCardType={true}
                                    showSelectSeller={true}
                                    showConditions={true}
                                    showPriceRange={true}
                                    showSortOrder={false}
                                />
                            </SwipeableDrawer>
                        ) : (
                            <SearchSidebar
                                isMobile={belowTablet}
                                loading={loading}
                                errors={errors}
                                aggregatedKeys={aggregatedKeys}
                                aggregatedValues={aggregatedValues}
                                parsedQueryResults={cleanedSearchQuery}
                                chipList={appliedFilters}
                                onRadioChange={handleRadioChange}
                                onCheckboxChange={handleCheckboxChange}
                                onClearAll={onClearAll}
                                onStockToggleChange={handleStockToggleChange}
                                onConditionsChange={handleConditionsChange}
                                onPriceRangeChange={handlePriceRangeChange}
                                onParamDelete={handleParamDelete}
                                showFindASeller={true}
                                inStockToggle={sessionInStock?.toString()}
                                showInStock={true}
                                showCardType={true}
                                showSelectSeller={true}
                                showConditions={true}
                                showPriceRange={true}
                                showSortOrder={false}
                            />
                        )}
                    </Box>
                    <div className={classes.searchPageCol}>
                        <Box className={classes.searchWrap}>
                            <Box className={classes.searchResults}>
                                <SearchResults
                                    loading={pwaOS === null ? loading : isInfiniteSearchLoading}
                                    results={pwaOS === null ? searchResults : infiniteResults}
                                    chipList={appliedFilters}
                                    errors={errors}
                                    sortOrder={sort}
                                    condition={condition}
                                    currentPage={p}
                                    isInfiniteScrolling={pwaOS !== null}
                                    hasMoreInfiniteResults={hasMoreInfiniteResults}
                                    onBottomDetected={handleBottomDetected}
                                />
                            </Box>
                            {pwaOS === null && (
                                <>
                                    <BSCDivider />
                                    <BSCPagination
                                        total={totalResults}
                                        defaultPage={parseInt(p) + 1 > 0 ? parseInt(p) + 1 : 1}
                                        sizePerPage={48}
                                        onChange={handlePageChange}
                                    />
                                </>
                            )}
                        </Box>
                    </div>
                    {pwaOS !== null && (
                        <PWASearchSortComponent
                            open={open}
                            currentSortOption={sort}
                            onSortChange={handleSortChange}
                            onClose={handleClose}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default SearchPage;
