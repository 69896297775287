/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import * as constants from '../../../../shared/constants';
import './styles.scss';
import {useAuthFunctions} from '../../../../AuthContext';

const useStyles = makeStyles((theme: Theme) => ({
    selectionsWrapper: {
        padding: '8px 8px 8px 6px !important',
    },
    selectionWrapper: {
        backgroundColor: theme.palette.common.white,
        padding: 8,
        marginBottom: 6,
    },
    emptySelectionWrapper: {
        height: 64,
        backgroundColor: 'transparent',
        marginBottom: 6,
    },
    largeIcon: {
        color: theme.palette.common.white,
        backgroundColor: 'black',
        overflow: 'hidden',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '50%',
        width: 47,
        height: 35,
        paddingTop: 11,
    },
}));

function PWABSCAccount() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const authFunc = useAuthFunctions();

    const [targetTab, setTargetTab] = useState(1);
    const handleDashSelection = (index: number) => {
        setTargetTab(index);
        switch (index) {
            case 0:
                if (localStorage.getItem('accessToken') === null) {
                    dispatch({
                        type: constants.PWA_ACCOUNT_INTENT,
                        payload: {
                            accountIntent: 'signIn',
                        },
                    });
                    history.push('/pwa/dashboard/buyer');
                    dispatch({
                        type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                        payload: {
                            pwaBuyerDashboardSelection: 0,
                        },
                    });
                }
                break;
            case 1:
                if (localStorage.getItem('accessToken') === null) {
                    dispatch({
                        type: constants.PWA_ACCOUNT_INTENT,
                        payload: {
                            accountIntent: 'signUp',
                        },
                    });
                    history.push('/pwa/dashboard/buyer');
                    dispatch({
                        type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                        payload: {
                            pwaBuyerDashboardSelection: 0,
                        },
                    });
                }
                break;
            case 2:
                history.push('../../faq');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 6,
                    },
                });
                break;
            case 3:
                history.push('../../support');
                dispatch({
                    type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                    payload: {
                        pwaBuyerDashboardSelection: 7,
                    },
                });
                break;
            case 4:
                localStorage.setItem('isResetPassword', 'true');
                authFunc.resetPasswordPWA();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'pageview',
            page: {
                path: location.pathname,
                search: location.search,
                hash: location.hash,
            },
        });
    }, [location]);

    useEffect(() => {
        dispatch({
            type: constants.PWA_NAVBAR_ACTIVE_TAB,
            payload: {
                pwaNavbarActive: 1,
            },
        });
    }, []);

    return (
        <>
            <div className={classes.selectionsWrapper}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container direction="row">
                            <Grid item xs={12} onClick={() => handleDashSelection(0)}>
                                <Grid container alignItems="center" className={classes.selectionWrapper}>
                                    <Grid item container xs={2} justifyContent="center">
                                        <div className={classes.largeIcon}>
                                            <span className="material-symbols-outlined">login</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <BSCTypography size18 style={{fontWeight: 700}}>
                                            Sign In
                                        </BSCTypography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} onClick={() => handleDashSelection(1)}>
                        <Grid container alignItems="center" className={classes.selectionWrapper}>
                            <Grid item container xs={2} justifyContent="center">
                                <div className={classes.largeIcon}>
                                    <span className="material-symbols-outlined">add_circle</span>
                                </div>
                            </Grid>
                            <Grid item xs={10}>
                                <BSCTypography size18 style={{fontWeight: 700}}>
                                    Create Account
                                </BSCTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} onClick={() => handleDashSelection(4)}>
                        <Grid container alignItems="center" className={classes.selectionWrapper}>
                            <Grid item container xs={2} justifyContent="center">
                                <div className={classes.largeIcon}>
                                    <span className="material-symbols-outlined">lock_reset</span>
                                </div>
                            </Grid>
                            <Grid item xs={10}>
                                <BSCTypography size18 style={{fontWeight: 700}}>
                                    Forgot Password
                                </BSCTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" className={classes.emptySelectionWrapper}>
                            <Grid item xs={12}>
                                &nbsp;
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} onClick={() => handleDashSelection(2)}>
                        <Grid container alignItems="center" className={classes.selectionWrapper}>
                            <Grid item container xs={2} justifyContent="center">
                                <div className={classes.largeIcon}>
                                    <span className="material-symbols-outlined" style={{fontSize: '32px !important'}}>
                                        question_mark
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={10}>
                                <BSCTypography size18 style={{fontWeight: 700}}>
                                    FAQs
                                </BSCTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} onClick={() => handleDashSelection(3)}>
                        <Grid container alignItems="center" className={classes.selectionWrapper}>
                            <Grid item container xs={2} justifyContent="center">
                                <div className={classes.largeIcon}>
                                    <span className="material-symbols-outlined">contact_support</span>
                                </div>
                            </Grid>
                            <Grid item xs={10}>
                                <BSCTypography size18 style={{fontWeight: 700}}>
                                    Contact Support
                                </BSCTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default PWABSCAccount;
