/* eslint-disable @typescript-eslint/no-unused-vars */
import {AccountInfo, Configuration, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import useDateNormalizer from '../../../../client/common/normalizer/dateTimeNormalizer';
import {ReportsApi} from '../../../../client/openapitools/seller';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {RootState} from '../../../../redux/reducers';
import SalesSummaryHeader from './components/salesSummaryHeader';
import SalesSummaryTable from './components/salesSummaryTable';

const useStyles = makeStyles((theme: Theme) => ({
    rootPWA: {
        height: 'calc(100vh - 220px)',
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
}));

function SellersSalesSummary() {
    const classes = useStyles();
    const location = useLocation();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {apiAuthConfig} = useAuthState();
    let reportsApi = new ReportsApi(apiAuthConfig);
    const dateNormalizer = useDateNormalizer();
    const [range, setRange] = useState('today');
    const {state} = useLocation();
    const [startDate, setStartDate] = useState(dateNormalizer.getApiDateTime(moment().format('MM/DD/YYYY')));
    const [endDate, setEndDate] = useState(dateNormalizer.getApiDateTime(moment().format('MM/DD/YYYY')));

    const summaryRef = {
        range: [
            {
                key: 'custom',
                name: 'Custom Date Range',
            },
            {
                key: 'today',
                name: 'Today',
            },
            {
                key: 'yesterday',
                name: 'Yesterday',
            },
            {
                key: 'mtd',
                name: 'Month To Date',
            },
            {
                key: 'ytd',
                name: 'Year To Date',
            },
            {
                key: 'lastMonth',
                name: 'Last Month',
            },
            {
                key: 'lastQuarter',
                name: 'Last Quarter',
            },
            {
                key: 'lastYear',
                name: 'Last Year',
            },
        ],
    };

    const handleRangeChange = e => {
        let tmpStart = moment(new Date());
        let tmpEnd = moment(new Date());
        switch (e.target.value) {
            case 'custom':
                //    setUseCustomDateRange(true);
                setRange(e.target.value);
                return;
                break;
            case 'today':
                //setUseCustomDateRange(false);
                //tmpEnd = tmpEnd.add(1, 'day');
                break;
            case 'yesterday':
                tmpStart = tmpStart.add(-1, 'day');
                tmpEnd = tmpEnd.add(-1, 'day');
                break;
            case 'mtd':
                tmpStart = moment().startOf('month');
                break;
            case 'ytd':
                tmpStart = moment().startOf('year');
                break;
            case 'lastMonth':
                tmpStart = tmpStart.add(-1, 'month').startOf('month');
                tmpEnd = tmpEnd.add(-1, 'month').endOf('month');
                break;
            case 'lastQuarter':
                tmpStart = tmpStart.add(-1, 'quarter').startOf('quarter');
                tmpEnd = tmpEnd.add(-1, 'quarter').endOf('quarter');
                break;
            case 'lastYear':
                tmpStart = tmpStart.add(-1, 'year').startOf('year');
                tmpEnd = tmpEnd.add(-1, 'year').endOf('year');
                break;
            default:
                break;
        }
        setStartDate(dateNormalizer.getApiDateTime(tmpStart));
        setEndDate(dateNormalizer.getApiDateTime(tmpEnd));

        setRange(e.target.value);
    };

    const handlePrint = () => {
        window.print();
    };

    const perPage = 400; //was 30 revert after api updates
    const [page] = useState(0); //add set page
    const [salesSummary, setSalesSummary] = useState(null);
    const [salesSummaryLoading, setSalesSummaryLoading] = useState(false);
    const {instance} = useMsal();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    useEffect(() => {
        const reportConfig = {
            startDate: dateNormalizer.getApiDateTime(startDate),
            endDate: dateNormalizer.getApiDateTime(endDate),
            page: page,
            size: perPage,
        };
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            reportsApi = new ReportsApi(apiConfig);
                            const response = await reportsApi.getSalesDailySummary(reportConfig);
                            setSalesSummaryLoading(false);
                            setSalesSummary(response);
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error fetching sales summary',
                                contextKey: 'SalesSummary',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [startDate, endDate, page, perPage, apiAuthConfig]);

    const changeStartDate = useCallback(
        e => {
            setRange('custom');
            setStartDate(dateNormalizer.getApiDateTime(e.target.value));
        },
        [dateNormalizer]
    );
    const changeEndDate = useCallback(
        e => {
            setRange('custom');
            setEndDate(dateNormalizer.getApiDateTime(e.target.value));
        },
        [dateNormalizer]
    );

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'pageview',
            page: {
                path: location.pathname,
                search: location.search,
                hash: location.hash,
            },
        });
    }, [location]);

    useEffect(() => {
        if (state?.search) {
            setRange(state?.search);
        }
    }, []);

    return (
        <BSCContainer variant="page" className={pwaOS !== null ? classes.rootPWA : null}>
            <SalesSummaryHeader
                range={range}
                onSelectRange={handleRangeChange}
                summaryRef={summaryRef}
                startDate={startDate}
                endDate={endDate}
                onPrint={handlePrint}
                setStartDate={changeStartDate}
                setEndDate={changeEndDate}
            />
            <SalesSummaryTable sizePerPage={perPage} page={page} salesSummary={salesSummary?.data} loading={salesSummaryLoading} />
        </BSCContainer>
    );
}

export default SellersSalesSummary;
