import React, {useEffect} from 'react';
import {Switch, Route, useLocation} from 'react-router';
import ReferencesClient from '../../../../../../client/references/referencesClient';
import ReplyMessage from './components/replyMessage';
import CreateMessage from './components/createMessage';
import MessageList from './components/messageList';
import {useQuery} from '../../../../../../packages/core/src/hooks/useQuery';

function Messages() {
    const {data: msgRef} = useQuery(() => ReferencesClient.getMessageReferences(), []);
    const location = useLocation();

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'pageview',
            page: {
                path: location.pathname,
                search: location.search,
                hash: location.hash,
            },
        });
    }, [location]);

    return (
        <Switch>
            <Route exact path="/dashboard/messages">
                <MessageList msgRef={msgRef} />
            </Route>
            <Route exact path={'/dashboard/messages/reply/:messageId'}>
                <ReplyMessage isMarketPlace messageRef={msgRef} orderUrl="/dashboard/orderHistory" />
            </Route>
            <Route exact path={'/dashboard/messages/create/'}>
                <CreateMessage isMarketPlace messageRef={msgRef} orderUrl="/dashboard/orderHistory" />
            </Route>
        </Switch>
    );
}

export default Messages;
