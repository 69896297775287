/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import AlertContextProvider from './AlertContext';
import BSCFooter from './bscFooter/bscFooter';
import BSCCardBox from './features/common/bscCardBox/bscCardBox';
import BSCLoading from './features/common/bscLoading/bscLoading';
import useReduxCart from './features/hooks/redux/cart/useReduxCart';
import useReduxUser from './features/hooks/redux/user/useReduxUser';
import {useQuery} from './packages/core/src/hooks/useQuery';
import Marketplace from './platforms/marketplace/marketplace';
import PWA from './platforms/pwa/pwa';
import SellersLocker from './platforms/sellersLocker/sellersLocker';
import {ReactComponent as BSCBuyersLogo} from './svgs/bscBuyerblack.svg';
import Toasty from './toasty';

import {AccountInfo, InteractionRequiredAuthError, SilentRequest} from '@azure/msal-browser';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {useDispatch, useSelector} from 'react-redux';
import {AUTH_REQUESTS} from './authConfig';
import {useAuthFunctions, useAuthState} from './AuthContext';
import {ReferenceApi} from './client/openapitools/common/api';
import {CartApi, CartRequest, CartRequestActionEnum, UserApi} from './client/openapitools/marketplace/api';
import useReduxToast from './features/hooks/redux/toast/useReduxToast';
import useScreenSnap from './packages/core/src/hooks/useScreenSnap';
import ProductReferenceContextProvider from './ProductReferenceContext';
import {RootState} from './redux/reducers';
import {changePortal as apiConfigChangePortal} from './redux/slices/apiConfig.slice';
import {changePortal} from './redux/slices/portal.slice';
import {store} from './redux/store';
// import SmartBanner from 'react-smartbanner';
// import './smartbanner.css';

declare global {
    interface Window {
        dataLayer: Array<{event: string; page: {path: string; search: string; hash: string}}>;
    }
}

function App() {
    const state = store.getState();
    const {isLoginInProgress, apiAuthConfig} = useAuthState();
    const isAuthenticated = useIsAuthenticated();
    const {setUser, getCartSessionId} = useReduxUser();
    const {getCart, mergeUserCart} = useReduxCart();
    const referenceApi = new ReferenceApi(apiAuthConfig);
    const {instance, inProgress} = useMsal();
    const {data: productReferences} = useQuery(() => referenceApi.getProductReference(), []);
    const sellerInRoute = useRouteMatch('/sellers');
    const reduxDispatch = useDispatch();
    const isMarketPlace = !sellerInRoute;
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const {isBelowDesktop, isBelowTablet} = useScreenSnap();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);

    navigator.serviceWorker.addEventListener('message', event => {
        // console.log('>>> ', event);
    });

    const mobileOS = ['Android', 'iOS', 'Windows'];
    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    const alertStrings = {
        addItemToCart: 'Your Cart is Empty',
        mustSignInForCheckout: 'Sign in to complete checkout',
    };

    const changePortalToSellers = () => {
        reduxDispatch(changePortal({context: 'sellers'}));
        reduxDispatch(apiConfigChangePortal({context: 'sellers'}));
    };

    const changePortalToBuyers = () => {
        reduxDispatch(changePortal({context: 'buyers'}));
        reduxDispatch(apiConfigChangePortal({context: 'buyers'}));
    };

    useEffect(() => {
        isMarketPlace ? changePortalToBuyers() : changePortalToSellers();
    }, [isMarketPlace]);

    const fetchUser = async () => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const userApi = new UserApi(apiConfig);
                            const response = await userApi.getUserProfile();
                            setUser(response.data);
                        } catch (error) {
                            console.error('Error fetching user', error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred.',
                                contextKey: 'Authentication',
                            });
                        }
                    }
                })
                .catch(async error => {
                    // if (error instanceof InteractionRequiredAuthError) {
                    // Fallback to interactive method
                    // await instance.acquireTokenRedirect(tokenRequest);
                    // } else {
                    console.error(error);
                    authFunc.logout();
                    // }
                });
        }
    };

    const mergeTheCart = async (cartItem: CartRequest) => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const cartApi = new CartApi(apiConfig);
                            cartItem.action = CartRequestActionEnum.Merge;
                            const response = await cartApi.update(cartItem);
                            mergeUserCart(cartItem);
                        } catch (error) {
                            console.error('Error merging cart', error);
                        }
                    }
                })
                .catch(error => {
                    // addToast({
                    //     severity: 'error',
                    //     message: 'User must be Signed In.',
                    //     contextKey: 'Authentication',
                    // });
                    // setTimeout(() => {
                    console.error(error);
                    authFunc.logout();
                    // }, 5000);
                });
        }
    };

    useEffect(() => {
        let localId = undefined;
        if (inProgress === 'none') {
            localId = getCartSessionId();

            if (localId) {
                if (isMarketPlace) {
                    fetchUser();
                    mergeTheCart({cartSessionId: localId});
                }
            }

            if (isMarketPlace) {
                getCart(isAuthenticated);
            }
        }
    }, [inProgress, isMarketPlace, apiAuthConfig]);

    const [isIOSDevice, setIsIOSDevice] = useState(false);
    useEffect(() => {
        let osName: string = null;
        if (navigator.userAgent.indexOf('Win') !== -1) osName = 'Windows';
        if (navigator.userAgent.indexOf('Mac') !== -1) osName = 'Mac';
        if (navigator.userAgent.indexOf('Linux') !== -1) osName = 'Linux';
        if (navigator.userAgent.indexOf('Android') !== -1) osName = 'Android';
        if (navigator.userAgent.indexOf('like Mac') !== -1) osName = 'iOS';
        const isMobileDevice = window.matchMedia('only screen and (max-width: 1024px)').matches;
        if (isMobileDevice) {
            if (osName === 'Mac') {
                osName = 'iOS';
            }
        }
        if (osName === 'iOS') {
            setIsIOSDevice(true);
        }
        document.title = 'BuySportsCards.com: Millions of Sports Cards for Sale';
        window.addEventListener('error', e => {
            if (
                e.message === 'ResizeObserver loop limit exceeded' ||
                e.message === 'ResizeObserver loop completed with undelivered notifications.'
            ) {
                const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
                const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    //This should be more reliable and not constantly stick on the loading screen
    // if (isLoginInProgress || authenticationProcess === 'logout' || authenticationProcess === 'handleRedirect') {
    if (inProgress !== 'none') {
        return (
            <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <BSCCardBox style={{maxWidth: 400, margin: 'auto'}}>
                    <BSCBuyersLogo style={{maxWidth: 100, margin: 'auto', display: 'block', marginBottom: 16}} />
                    <BSCLoading
                        loading
                        loadingReasons={{
                            ['Loading ... ']: true,
                        }}
                    />
                </BSCCardBox>
            </div>
        );
    }

    return (
        <AlertContextProvider value={{messages: alertStrings}}>
            <ProductReferenceContextProvider value={{productReferences: productReferences?.data}}>
                {/* isIOSDevice && <SmartBanner title={'BuySportsCards'} force="ios" /> */}
                <Toasty />
                {window.location.pathname !== '/pwa' && (
                    <>
                        <>
                            {pwaOS && isBelowDesktop && mobileOS.some(loneOS => pwaOS.includes(loneOS)) && (
                                // <div className="content" style={{height: 'calc(100% - 64px)', overflowX: 'hidden', overflowY: 'scroll'}}>
                                <div className="content">{isMarketPlace ? <Marketplace /> : <SellersLocker />}</div>
                            )}
                        </>
                        <>{pwaOS === null && <div className="content">{isMarketPlace ? <Marketplace /> : <SellersLocker />}</div>}</>
                    </>
                )}
                {window.location.pathname === '/pwa' && (
                    // <div className="content" style={{height: 'calc(100% - 64px)', overflowX: 'hidden', overflowY: 'scroll'}}>
                    <div className="content">
                        <PWA />
                    </div>
                )}
                <BSCFooter isMarketPlace={isMarketPlace} />
            </ProductReferenceContextProvider>
        </AlertContextProvider>
    );
}

export default App;
