/* eslint-disable @typescript-eslint/no-unused-vars */
import {makeStyles, Theme} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../../../redux/reducers';
import ProductInfoDetails from './productInfoDetails';
import ProductInfoHeader from './productInfoHeader';
import ProductListings from './productListings';

import {useHistory, useLocation, useParams} from 'react-router';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import useReduxCart from '../../../../features/hooks/redux/cart/useReduxCart';
// import {useQuery} from '../../../../packages/core/src/hooks/useQuery';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {Alert} from '@material-ui/lab';
import {Helmet} from 'react-helmet-async';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {useCardNormalizer} from '../../../../client/common/card/cardNormalizer';
import {CartRequest, CartRequestActionEnum} from '../../../../client/openapitools/marketplace';
import {CartApi} from '../../../../client/openapitools/marketplace/api';
import BSCCardBox from '../../../../features/common/bscCardBox/bscCardBox';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import useApi from '../../../../features/hooks/redux/apiConfig/useApi';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';
import * as constants from '../../../../shared/constants';
import StorefrontBanner from '../search/StorefrontBanner';
import MakeAnOfferModal from './makeAnOfferModal';
import ReportModal from './reportModal';
import SellProductModal from './SellProductModal';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    box: {
        background: theme.palette.common.white,
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: 4,
        paddingTop: theme.spacing(2),
    },
    buttonWrap: {
        paddingTop: 0,
    },
    heading: {
        padding: theme.spacing(0, 2),
    },
    topWrap: {
        marginBottom: theme.spacing(2),
    },
    displayNone: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        paddingTop: 8,
        paddingBottom: 8,
    },
    historyAlert: {
        height: '27vh',
        margin: '16px',
        position: 'relative',
        top: '12%',
        textAlign: 'center',
    },
    productDetails: {
        position: 'absolute',
        left: -4200,
    },
    noBullet: {
        listStyle: 'none',
    },
    breadcrumbs: {
        color: '#0f0f0f',
        display: 'inline-block',
        textDecoration: 'underline',
        cursor: 'pointer',
        ['&:link, &:visited']: {
            color: 'inherit !important',
        },
    },
    breadcrumbsNoLink: {
        display: 'inline-block',
        textDecoration: 'none',
        cursor: 'default',
    },
    breadcrumbsSeparator: {
        display: 'inline-block',
        textDecoration: 'none',
        padding: '0 8px',
        cursor: 'default',
    },
    scrollablePWA: {
        height: 'calc(100vh - 205px)',
        overflow: 'hidden',
        background: '#fff',
    },
}));

interface SelectedItem {
    productId: string;
    quantity: number;
    offers: boolean;
}

function ProductPage() {
    const classes = useStyles();
    const {instance} = useMsal();
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const {apiAuthConfig} = useAuthState();
    const cardNormalizer = useCardNormalizer();
    const {isBelowTablet, isMobile} = useScreenSnap();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {productId} = useParams<{productId: string}>();
    const history = useHistory();
    const api = useApi();
    const dispatch = useDispatch();
    const [productInfo, setProductInfo] = useState(null);
    const [productListings, setProductListings] = useState([]);
    const [reportOpen, toggleReportOpen] = useState(false);
    const [sellOpen, toggleSellOpen] = useState(false);
    const {getCartSessionId, isAuth, sessionId, userState} = useReduxUser();
    const {cartState, getCart} = useReduxCart();
    const [cardListingLoading, setCardListingLoading] = useState(true);
    const [cardListing, setCardListing] = useState(null);
    const [selectedListing, setSelectedListing] = useState<SelectedItem>(null);
    const [makeAnOfferModalOpen, setMakeAnOfferModalOpen] = useState(false);
    const authFunc = useAuthFunctions();
    const [actionsVisible, setActionsVisible] = useState(true);

    const [selectedListings, setSelectedListings] = useState<SelectedItem[]>(null);
    const handleSelectedListings = useCallback(
        selectedProducts => {
            if (selectedProducts) {
                setSelectedListings(selectedProducts);
                setSelectedListing(selectedProducts[0]);
                const {productId, quantity, offers, sellerId} = selectedProducts[0];
                if (quantity === 0 && productId === selectedListing.productId) {
                    setSelectedListings(null);
                    setActionsVisible(true);
                } else if (quantity > 0) {
                    if ((sellerId && userState && sellerId !== userState?.id) || !userState) {
                        setSelectedListing({productId: productId, quantity: quantity, offers: offers});
                        setActionsVisible(true);
                    } else {
                        setActionsVisible(false);
                    }
                }
            }
        },
        [userState]
    );

    const [selectedSellerId, setSelectedSellerId] = useState(null);
    const [selectedSellerName, setSelectedSellerName] = useState(null);
    const [breadcrumbsCardCondition, setBreadcrumbsCardCondition] = useState('');
    const [breadcrumbsInStock, setBreadcrumbsInStock] = useState(true);
    const [breadcrumbsSortOrder, setBreadcrumbsSortOrder] = useState('');
    const [isShowingAllListings, setIsShowingAllListings] = useState(true);
    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        let hasLeftSellerStorefront = false;
        if (marketplaceState) {
            if (marketplaceState.breadcrumbsCardCondition) {
                setBreadcrumbsCardCondition('&condition=' + marketplaceState.breadcrumbsCardCondition);
            } else {
                setBreadcrumbsCardCondition('');
            }
            if (marketplaceState.breadcrumbsSortOrder) {
                setBreadcrumbsSortOrder('&sort=' + marketplaceState.breadcrumbsSortOrder);
            } else {
                setBreadcrumbsSortOrder('');
            }
            setBreadcrumbsInStock(marketplaceState.breadcrumbsInStock);
            if (marketplaceState.showAllListings === true) {
                setIsShowingAllListings(true);
                if (cardListing !== null) {
                    api.marketplaceApi.cardApi.getCardListings(productId).then(response => {
                        const responseData = response.data;
                        setCardListing(responseData);
                        setProductInfo(responseData);
                        setProductListings(responseData.listings);
                        setCardListingLoading(false);
                    });
                }
            } else {
                setIsShowingAllListings(false);
                const tempSellerId =
                    Object.keys(marketplaceState.sellerStorefront).length !== 0 ? marketplaceState.sellerStorefront['sellerId'] : null;
                const tempSellerName =
                    Object.keys(marketplaceState.sellerStorefront).length !== 0 ? marketplaceState.sellerStorefront['sellerName'] : null;
                if ((tempSellerId === undefined || tempSellerId === null) && selectedSellerId !== null) {
                    hasLeftSellerStorefront = true;
                    setCardListing(null);
                    setSelectedSellerId(null);
                    setSelectedSellerName(null);
                }
                if (tempSellerId === undefined || tempSellerId === null) {
                    // storefront seller is not present - fetch regular card listing
                    if (cardListing === null || hasLeftSellerStorefront) {
                        api.marketplaceApi.cardApi.getCardListings(productId).then(response => {
                            const responseData = response.data;
                            setCardListing(responseData);
                            setProductInfo(responseData);
                            setProductListings(responseData.listings);
                            setCardListingLoading(false);
                        });
                    }
                } else {
                    // storefront seller is not present - fetch card listing by storefront seller ID
                    if (cardListing === null) {
                        setSelectedSellerId(tempSellerId);
                        setSelectedSellerName(tempSellerName);
                        api.marketplaceApi.cardApi.getCardListingsBySeller(productId, tempSellerId).then(response => {
                            const responseData = response.data;
                            setCardListing(responseData);
                            setProductInfo(responseData);
                            setProductListings(responseData.listings);
                            setCardListingLoading(false);
                        });
                    }
                }
            }
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useCallback(() => {
        setSelectedListing(null);
        setActionsVisible(true);
    }, []);

    const handleAddToCart = useCallback(async () => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const cartApi = new CartApi(apiConfig);
                            const cartSessionId = getCartSessionId();
                            let cartItem: CartRequest;
                            let response;
                            selectedListings.forEach(async (listing, index) => {
                                cartItem = {
                                    productId: listing.productId,
                                    cartSessionId,
                                    quantity: listing.quantity,
                                    action: CartRequestActionEnum.Add,
                                };
                                response = await cartApi.update(cartItem);
                                if (response.status === 200) {
                                    if (index === 0) {
                                        addToast({
                                            severity: 'success',
                                            message: 'Successfully Added To Cart',
                                        });
                                    }
                                    getCart();
                                    return response.data;
                                    dispatch({
                                        type: constants.HAS_NEW_CART_QUANTITY,
                                        payload: true,
                                    });
                                }
                            });
                        } catch (error) {
                            console.error('Error fetching user', error);
                            addToast({
                                severity: 'error',
                                message: error.message,
                                contextKey: 'Update Cart',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        } else {
            try {
                const cartApi = new CartApi();
                const cartSessionId = getCartSessionId();
                let cartItem: CartRequest;
                let response;
                let hasShownToast = false;
                selectedListings.forEach(async listing => {
                    cartItem = {
                        productId: listing.productId,
                        cartSessionId,
                        quantity: listing.quantity,
                        action: CartRequestActionEnum.Add,
                    };
                    response = await cartApi.update(cartItem);
                    if (response.status === 200) {
                        if (!hasShownToast) {
                            addToast({
                                severity: 'success',
                                message: 'Successfully Added To Cart',
                            });
                            getCart();
                            return response.data;
                        } else {
                            hasShownToast = true;
                        }
                        dispatch({
                            type: constants.HAS_NEW_CART_QUANTITY,
                            payload: true,
                        });
                    }
                });
            } catch (error) {
                console.error('Error fetching user', error);
                addToast({
                    severity: 'error',
                    message: error.message,
                    contextKey: 'Update Cart',
                });
            }
        }
    }, [history, selectedListing, productInfo, sessionId, isAuth, getCart, getCartSessionId]);

    const {addToast} = useReduxToast();

    const handleMakeAnOffer = useCallback(() => {
        if (!isAuthenticated) {
            addToast({
                severity: 'error',
                message: 'User must be Signed In.',
                contextKey: 'Authentication',
            });
        } else if (!userState?.buyerProfile?.paymentMethods || (userState?.buyerProfile?.paymentMethods?.length ?? 0) === 0) {
            addToast({
                severity: 'error',
                message: 'Please set up a Payment method in your Profile to make an offer.',
                contextKey: 'Payment',
            });
        } else if ((userState?.buyerProfile?.paymentMethods?.length ?? 0) > 0) {
            setMakeAnOfferModalOpen(true);
        }
    }, [userState, isAuthenticated]);

    const handleSellThis = useCallback(() => {
        if (userState) {
            if (userState.roleId === '2') {
                //is seller
                history.push(`/sellers/sell-your-card/add/${productId}`);
                dispatch({
                    type: constants.SELLERS_LOCKER_CARDS_ACTIVE_TAB,
                    payload: {sellersLockerCardsActiveTab: 0},
                });
            } else if (userState.roleId === '1') {
                //is buyer
                history.push('/sellers/signup');
            }
        } else {
            authFunc.login();
        }
    }, [userState]);

    const handleReport = useCallback(() => {
        const account = instance.getActiveAccount();
        if (account === null) {
            addToast({
                severity: 'error',
                message: 'User must be Signed In.',
                contextKey: 'Report A Problem',
            });
        } else {
            toggleReportOpen(true);
        }
    }, []);

    const handleClose = () => {
        toggleReportOpen(false);
        toggleSellOpen(false);
    };

    const [sportBreadcrumb, setSportBreadcrumb] = useState('');
    const [yearBreadcrumb, setYearBreadcrumb] = useState('');
    const [setNameBreadcrumb, setSetNameBreadcrumb] = useState('');
    const [variantBreadcrumb, setVariantBreadcrumb] = useState('');
    const [variantNameBreadcrumb, setVariantNameBreadcrumb] = useState('');
    const [allBreadcrumb, setAllBreadcrumb] = useState('');
    const [tabTitle, setTabTitle] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [breadcrumbSetName, setBreadcrumbSetName] = useState('');
    useEffect(() => {
        if (productInfo) {
            let metaStage;
            let setNameSplitPtr = productInfo.setName.indexOf(' ');
            setBreadcrumbSetName(productInfo.setName.substring(++setNameSplitPtr, productInfo.setName.length));
            if (productInfo.variantName !== '') {
                setTabTitle(`${productInfo.players} #${productInfo.cardNo} - ${productInfo.setName} - ${productInfo.variantName}`);
                metaStage = `${productInfo.players} #${productInfo.cardNo} - ${productInfo.setName} - ${productInfo.variantName}`;
            } else {
                setTabTitle(`${productInfo.players} #${productInfo.cardNo} - ${productInfo.setName}`);
                metaStage = `${productInfo.players} #${productInfo.cardNo} - ${productInfo.setName}`;
            }
            if (productInfo.teamName !== '') {
                metaStage = `${metaStage} - ${productInfo.teamName}`;
            }
            if (productInfo.playerAttributeAlias !== '') {
                metaStage = metaStage + ' - ' + productInfo.playerAttributeAlias.trim();
            }
            metaStage = `${metaStage} - ${productInfo.sport}`;
            setMetaTitle(metaStage);

            const rawSport = productInfo.sport.toLowerCase();
            let spacePtr = productInfo.setName.indexOf(' ');
            let rawYear = productInfo.setName.substring(0, spacePtr);
            if (rawYear.includes('-')) {
                rawYear = rawYear.substring(0, rawYear.indexOf('-'));
            }
            const rawSetName = productInfo.setName.substring(++spacePtr, productInfo.setName.length).toLowerCase();
            const setName = encodeURIComponent(rawSetName).replace(new RegExp('%20', 'g'), '-');
            const rawVariant = productInfo.variant.toLowerCase();
            const rawVariantName = productInfo.variantName.toLowerCase();
            const variantName = encodeURIComponent(rawVariantName).replace(new RegExp('%20', 'g'), '-');
            setSportBreadcrumb(
                `${window.location.origin}/search?inStock=${breadcrumbsInStock}&p=0&sport[]=${rawSport}` +
                    breadcrumbsCardCondition +
                    breadcrumbsSortOrder
            );
            setYearBreadcrumb(
                `${window.location.origin}/search?inStock=${breadcrumbsInStock}&p=0&sport[]=${rawSport}&year[]=${rawYear}` +
                    breadcrumbsCardCondition +
                    breadcrumbsSortOrder
            );
            setSetNameBreadcrumb(
                `${window.location.origin}/search?inStock=${breadcrumbsInStock}&p=0&sport[]=${rawSport}&year[]=${rawYear}&setName[]=${setName}` +
                    breadcrumbsCardCondition +
                    breadcrumbsSortOrder
            );
            setVariantBreadcrumb(
                `${window.location.origin}/search?inStock=${breadcrumbsInStock}&p=0&sport[]=${rawSport}&year[]=${rawYear}&setName[]=${setName}&variant[]=${rawVariant}` +
                    breadcrumbsCardCondition +
                    breadcrumbsSortOrder
            );
            setVariantNameBreadcrumb(
                `${window.location.origin}/search?inStock=${breadcrumbsInStock}&p=0&sport[]=${rawSport}&year[]=${rawYear}&setName[]=${setName}&variant[]=${rawVariant}&variantName[]=${variantName}` +
                    breadcrumbsCardCondition +
                    breadcrumbsSortOrder
            );
            setAllBreadcrumb(
                `${window.location.origin}/search?inStock=${breadcrumbsInStock}&p=0` + breadcrumbsCardCondition + breadcrumbsSortOrder
            );
            dispatch({
                type: constants.CARD_IMAGE_DEFAULT,
                payload: {imageFront: productInfo.stockImgFront, imageBack: productInfo.stockImgBack},
            });
        }
    }, [productInfo]);

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'pageview',
            page: {
                path: location.pathname,
                search: location.search,
                hash: location.hash,
            },
        });
    }, [location]);

    useEffect(() => {
        dispatch({
            type: constants.SELLER_RESET,
        });
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {productInfo && (
                <>
                    <Helmet>
                        <title>{tabTitle}</title>
                        <link rel="canonical" href={process.env.REACT_APP_REDIRECT_URI + 'product/' + productId} />
                        <meta name="title" content={`${metaTitle} Cards`} />
                        <meta name="description" content={`${metaTitle} Cards`} />
                        <meta name="og:type" content="product" />
                        <meta name="og:url" content={window.location.href} />
                        <meta name="og:title" content={`${metaTitle} Cards`} />
                        <meta name="og:description" content={`${metaTitle} Cards`} />
                    </Helmet>
                    <BSCContainer
                        variant={isBelowTablet || isMobile ? '' : 'page'}
                        className={pwaOS !== null ? classes.scrollablePWA : null}
                    >
                        <div className={classes.displayNone}>
                            <a className={classes.breadcrumbs} href={allBreadcrumb}>
                                <BSCTypography label={'All Sports'} />
                            </a>
                            <div className={classes.breadcrumbsSeparator}>
                                <BSCTypography label={'>'} />
                            </div>
                            <a className={classes.breadcrumbs} href={sportBreadcrumb}>
                                <BSCTypography id="productInfoSport" label={productInfo.sport} />
                            </a>
                            <div className={classes.breadcrumbsSeparator}>
                                <BSCTypography label={'>'} />
                            </div>
                            <a className={classes.breadcrumbs} href={yearBreadcrumb}>
                                <BSCTypography label={productInfo.year} />
                            </a>
                            <div className={classes.breadcrumbsSeparator}>
                                <BSCTypography label={'>'} />
                            </div>
                            <a className={classes.breadcrumbs} href={setNameBreadcrumb}>
                                <BSCTypography label={breadcrumbSetName} />
                            </a>
                            <div className={classes.breadcrumbsSeparator}>
                                <BSCTypography label={'>'} />
                            </div>
                            <a className={classes.breadcrumbs} href={variantBreadcrumb}>
                                <BSCTypography label={productInfo.variant} />
                            </a>
                            <div className={classes.breadcrumbsSeparator}>
                                <BSCTypography label={'>'} />
                            </div>
                            {productInfo.variantName !== '' && (
                                <>
                                    <a className={classes.breadcrumbs} href={variantNameBreadcrumb}>
                                        <BSCTypography label={productInfo.variantName} />
                                    </a>
                                    <div className={classes.breadcrumbsSeparator}>
                                        <BSCTypography label={'>'} />
                                    </div>
                                </>
                            )}
                            <div className={classes.breadcrumbsNoLink}>
                                <BSCTypography bold label={`${productInfo.players} #${productInfo.cardNo}`} />
                            </div>
                        </div>

                        {/* <div className={classes.displayNone}>
                            <BSCBack label="Back to Results" fallbackRoute="/search" />
                        </div> */}

                        <div className={classes.productDetails}>
                            <h1>
                                {`${productInfo.players} #${productInfo.cardNo} ${productInfo.year} ${productInfo?.setName} `}{' '}
                                {cardNormalizer.getVariantAndVariantName(productInfo)}
                            </h1>
                            <h2>{productInfo?.setName}</h2>
                            <h3>{cardNormalizer.getVariantAndVariantName(productInfo)}</h3>
                            <h4>{productInfo?.teamName}</h4>
                            <h5>
                                {`${productInfo.players} #${productInfo.cardNo} ${productInfo.year} ${productInfo?.setName} `}{' '}
                                {cardNormalizer.getVariantAndVariantName(productInfo)} {productInfo?.teamName}
                            </h5>
                        </div>

                        {selectedSellerName && !isShowingAllListings && (
                            <StorefrontBanner
                                sellerId={selectedSellerId}
                                sellerName={selectedSellerName}
                                hasWideBanner={true}
                                cta="Show All Listings"
                            />
                        )}
                        <div className={classes.topWrap}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} container>
                                    <BSCCardBox className={classes.root}>
                                        {productInfo !== undefined && (
                                            <ProductInfoHeader productInfoData={productInfo} loading={cardListingLoading} />
                                        )}
                                        {(isBelowTablet || isMobile) && marketplaceState.isMobileSellActive && (
                                            <ProductListings
                                                selectedListing={selectedListing}
                                                onProductSelect={handleSelectedListings}
                                                onAddToCart={handleAddToCart}
                                                onMakeAnOffer={handleMakeAnOffer}
                                                loading={cardListingLoading}
                                                productListingsData={productListings}
                                                addToCartLoading={cartState.addToCartLoading || cartState.updateCartLoading}
                                                cardYear={productInfo.year}
                                            />
                                        )}
                                        {(isBelowTablet || isMobile) && marketplaceState.isMobileListActive && (
                                            <ProductInfoDetails
                                                productInfoData={productInfo}
                                                onReportClick={handleReport}
                                                onSellThisClick={handleSellThis}
                                            />
                                        )}
                                        {!isBelowTablet && !isMobile && (
                                            <ProductInfoDetails
                                                productInfoData={productInfo}
                                                onReportClick={handleReport}
                                                onSellThisClick={handleSellThis}
                                            />
                                        )}
                                        {(isBelowTablet || isMobile) && marketplaceState.isMobileHistoryActive && (
                                            <div className={classes.historyAlert}>
                                                <Alert severity="warning">Price History Coming Soon.</Alert>
                                            </div>
                                        )}
                                    </BSCCardBox>
                                </Grid>
                                {!isBelowTablet && !isMobile && (
                                    <Grid container direction="column" item xs={12} md={8}>
                                        <ProductListings
                                            selectedListing={selectedListing}
                                            onProductSelect={handleSelectedListings}
                                            onAddToCart={handleAddToCart}
                                            onMakeAnOffer={handleMakeAnOffer}
                                            loading={cardListingLoading}
                                            productListingsData={productListings}
                                            actionsVisible={actionsVisible}
                                            addToCartLoading={cartState.addToCartLoading || cartState.updateCartLoading}
                                            cardYear={productInfo.year}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                        {!isBelowTablet && !isMobile && (
                            <BSCCardBox>
                                <Alert severity="warning">Price History Coming Soon.</Alert>
                            </BSCCardBox>
                        )}
                        <ReportModal open={reportOpen} onClose={handleClose} listingId={selectedListing?.productId} cardId={productId} />
                        <SellProductModal open={sellOpen} onClose={handleClose} />
                        <MakeAnOfferModal
                            productInfo={productListings?.find(item => item?.productId === selectedListing?.productId)}
                            cardListing={cardListing?.data}
                            open={makeAnOfferModalOpen}
                            onModalClose={() => setMakeAnOfferModalOpen(false)}
                        />
                    </BSCContainer>
                </>
            )}
        </>
    );
}

export default ProductPage;
