import React, {useEffect, useState} from 'react';

import Grid from '@material-ui/core/Grid';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useCallback} from 'react';
import BSCLoading from '../../../../../../features/common/bscLoading/bscLoading';
import ShareSeller from '../../../sellersPortal/components/shareSeller';
import PrimaryContact from './components/primaryContact';

import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {useSelector} from 'react-redux';
import {AUTH_REQUESTS} from '../../../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../../../AuthContext';
import {AccountApi, UserApi} from '../../../../../../client/openapitools/seller/api';
import FormValidationsClient from '../../../../../../client/s3/formValidations/formValidationsClient';
import FormValidationsNormalizer from '../../../../../../client/s3/formValidations/formValidationsNormalizer';
import BSCAddress from '../../../../../../features/common/bscAddress/bscAddress';
import useReduxToast from '../../../../../../features/hooks/redux/toast/useReduxToast';
import {useQuery} from '../../../../../../packages/core/src/hooks/useQuery';
import {RootState} from '../../../../../../redux/reducers';
import PaymentSettings from './components/paymentSettings';
import SellerSettings from './components/sellerSettings';
import {useLocation} from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(1),
    },
    rootPWA: {
        display: 'flex',
        padding: theme.spacing(1),
        // height: 690,
        // overflowX: 'hidden',
        // overflowY: 'scroll',
    },
    shareSeller: {
        height: 'fit-content',
    },
}));

const Profile = () => {
    const classes = useStyles();
    const location = useLocation();
    const {instance} = useMsal();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {apiAuthConfig} = useAuthState();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    const [sellerInformation, setSellerInformation] = useState(null);
    const [loading, setLoading] = useState(true);

    const [updateSellerProfileLoading, setUpdateSellerProfileLoading] = useState(false);
    const updateSellerProfile = sellerProfileData => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        setUpdateSellerProfileLoading(true);
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const userApi = new UserApi(apiConfig);
                            await userApi.updateSellerUserProfile(sellerProfileData);
                            setUpdateSellerProfileLoading(false);
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to update Seller user profile.',
                                contextKey: 'Seller User Profile',
                            });
                            setUpdateSellerProfileLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.1',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const updateInventoryLive = status => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const sellerAccountApi = new AccountApi(apiConfig);
                            await sellerAccountApi.updateSellerInventoryStatus(status);
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to update Seller inventory status.',
                                contextKey: 'Seller Inventory',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.2',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const putSellerAddress = sellerAddressData => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const userApi = new UserApi(apiConfig);
                            const addressResponse = await userApi.updateAddress(sellerAddressData);
                            return addressResponse;
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to update Seller address',
                                contextKey: 'Seller Address',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const loadingReasons = {
        ['Loading Seller Profile Data']: loading,
        ['Updating Seller Profile Data']: updateSellerProfileLoading,
    };
    /* eslint-disable  @typescript-eslint/no-unused-vars */
    const {
        loading: formValidationsLoading,
        error: formValidationsErrors,
        data: formValidations,
    } = useQuery(() => FormValidationsClient.getFormValidations(), []);

    const saveProfile = useCallback(
        updated => {
            const data = sellerInformation.data;

            if (updated.paymentSettings) {
                data.sellerProfile.paymentSettings.fundingSourceName = updated.paymentSettings.accountName;
                data.sellerProfile.paymentSettings.accountNumber = updated.paymentSettings.accountNumber;
                data.sellerProfile.paymentSettings.bankAccountType = updated.paymentSettings.paymentOption;
            }

            if (updated.sellerSettings) {
                data.sellerProfile.listingSettings.offers = updated.sellerSettings.offers;
            }

            if (updated.shippingSettings) {
                data.sellerProfile.shippingSettings = updated.shippingSettings;
            }

            if (updated.storePhoneNumber) {
                data.sellerProfile.sellerEmailId = updated.sellerEmailId;
                data.sellerProfile.storePhoneNumber = updated.storePhoneNumber;
            }
            updateSellerProfile(data);
        },
        [updateSellerProfile]
    );

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'pageview',
            page: {
                path: location.pathname,
                search: location.search,
                hash: location.hash,
            },
        });
    }, [location]);

    const [refreshFlag, setRefreshFlag] = useState(false);
    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        const userApi = new UserApi(apiConfig);
                        const profileResponse = await userApi.getSellerUserProfile();
                        setLoading(false);
                        setSellerInformation(profileResponse);
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    console.log('>>>', error);
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.4',
                        contextKey: 'Authentication',
                    });
                    // setTimeout(() => {
                    //     authFunc.logout();
                    // }, 5000);
                });
        }
    }, [instance, refreshFlag]);

    return (
        <>
            {(loading || updateSellerProfileLoading) && (
                <BSCLoading loading={loading || updateSellerProfileLoading} loadingReasons={loadingReasons} />
            )}
            {!(loading || updateSellerProfileLoading) && sellerInformation && (
                <Grid container className={pwaOS === null ? classes.root : classes.rootPWA} spacing={2} direction="row">
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ShareSeller information={sellerInformation.data.sellerProfile} onTurnButtonClick={updateInventoryLive} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8} direction="column">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <PrimaryContact
                                    email={sellerInformation.data.sellerProfile.sellerEmailId}
                                    phoneNumber={sellerInformation.data.sellerProfile.storePhoneNumber}
                                    onSave={saveProfile}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BSCAddress
                                    addPadding={false}
                                    address={sellerInformation.data.sellerProfile.storeAddress}
                                    addressTypeDisplay="Address Information"
                                    addressType={sellerInformation?.data.sellerProfile.storeAddress?.type}
                                    formValidation={FormValidationsNormalizer.getShippingAddressValidationsSchema(formValidations)}
                                    onSaveAddress={putSellerAddress}
                                    onFormClose={() => setRefreshFlag(!refreshFlag)}
                                ></BSCAddress>
                            </Grid>
                            <Grid item xs={12}>
                                <SellerSettings
                                    listingSettings={sellerInformation.data.sellerProfile.listingSettings}
                                    shippingSettings={sellerInformation.data.sellerProfile?.shippingSettings}
                                    onSave={saveProfile}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PaymentSettings information={sellerInformation.data.sellerProfile.paymentSettings} onSave={saveProfile} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default Profile;
