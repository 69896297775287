import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import BSCSelect from '../../../../../features/common/bscSelect/bscSelect';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import BSCDateRangePicker from '../../../../../features/common/bscDatePicker/bscDateRangePicker';
import BSCHeaderBlock from '../../../../../features/common/bscHeaderBlock/bscHeaderBlock';
// import useForm from '../../../../../packages/core/src/hooks/useForm';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import {Grid, Hidden} from '@material-ui/core';
import {RootState} from '../../../../../redux/reducers';
import {useSelector} from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        // display: 'grid',
        // justifyItems: 'center',
        // alignItems: 'center',
        // rowGap: '10px',
        padding: '10px',
        // columnGap: '10px',
        backgroundColor: 'white',
        // [theme.breakpoints.up('lg')]: {
        //     gridTemplateColumns: '50px 1fr 2fr 1fr 50px',
        // },

        // [theme.breakpoints.down('md')]: {
        //     gridTemplateColumns: '1fr',
        // },
    },
    dateRange: {
        alignSelf: 'center',
    },
    dateRangeFont: {
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.subtitle1.fontSize,
        },
        color: 'black',
    },
    rangeValue: {
        marginLeft: '5px',
        width: '100%',
        '& div': {
            fontSize: theme.typography.subtitle1.fontSize,
        },
    },
    rangeOption: {
        fontSize: theme.typography.subtitle1.fontSize,
    },
    mlAuto: {
        float: 'right',
    },
    rangeSelect: {
        [theme.breakpoints.up('lg')]: {
            gridColumnStart: '2',
        },
    },
    rangePicker: {
        [theme.breakpoints.down('md')]: {
            gridRow: 'span 2',
        },
    },
    buttons: {
        display: 'flex',
        alignItems: 'flex-end',
        // [theme.breakpoints.down('xs')]: {
        //     gridColumnStart: '1',
        // },
        // [theme.breakpoints.up('lg')]: {
        //     gridColumnStart: '4',
        // },
    },
    buttonsNoDatePicker: {
        display: 'flex',
        alignItems: 'flex-end',
        // [theme.breakpoints.down('xs')]: {
        //     gridColumnStart: '1',
        // },
        // [theme.breakpoints.up('lg')]: {
        //     gridColumnStart: '4',
        // },
    },
    noPrint: {
        '@media print': {
            display: 'none',
        },
    },
    alignButton: {
        textAlign: 'right',
    },
    topPadding: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '10px',
        },
    },
}));

const SalesSummaryHeader = ({range, onSelectRange, summaryRef, onPrint, setStartDate, setEndDate, startDate, endDate}) => {
    const classes = useStyles();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const startProps = {
        inputKey: 'fromDate',
        placeholder: moment().format('MM/DD/YYYY'),
        onChange: setStartDate,
        value: startDate,
        setMinMaxValidation: false,
    };

    const endProps = {
        inputKey: 'toDate',
        placeholder: moment().format('MM/DD/YYYY'),
        onChange: setEndDate,
        value: endDate,
        setMinMaxValidation: false,
    };

    const gridProps = {xs: 12, lg: 6};
    const gridContainerProps = {};

    return (
        <>
            {marketplaceState.pwaSettings.pwaOS === '' && (
                <BSCHeaderBlock align="center" label="Sales Summary" size18 className={classes.noPrint} />
            )}
            <div className={classes.root}>
                <Grid container justifyContent="space-between">
                    <Grid item container xs={12} md={3}>
                        <Grid item xs={12} md={3} className={classes.dateRange}>
                            <BSCTypography capitalize variant="subtitle1" label="Date Range" className={classes.dateRangeFont} />
                        </Grid>
                        <Grid item xs={12} md={7} justifyContent="flex-start">
                            <BSCSelect onInputChange={onSelectRange} inputKey="dateRange" value={range} className={classes.rangeValue}>
                                {summaryRef.range.map(type => {
                                    return (
                                        <MenuItem key={type.key} value={type.key} className={classes.rangeOption}>
                                            {type.name}
                                        </MenuItem>
                                    );
                                })}
                            </BSCSelect>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5} className={classes.topPadding}>
                        <BSCDateRangePicker
                            startPickerProps={startProps}
                            endPickerProps={endProps}
                            startGridProps={gridProps}
                            endGridProps={gridProps}
                            gridContainerProps={gridContainerProps}
                            toFromHidded={{mdDown: true}}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.alignButton}>
                        <Hidden mdDown>
                            <Box ml={5}>
                                <BSCButton color="secondary" onClick={onPrint}>
                                    <BSCTypography label="print" capitalize />
                                </BSCButton>
                            </Box>
                        </Hidden>
                    </Grid>
                </Grid>
                {/* <div className={classes.rangeSelect}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <BSCTypography capitalize variant="subtitle1" className={classes.dateRange} label="Date Range" />
                        <BSCSelect onInputChange={onSelectRange} inputKey="dateRange" value={range} className={classes.rangeValue}>
                            {summaryRef.range.map(type => {
                                return (
                                    <MenuItem key={type.key} value={type.key} className={classes.rangeOption}>
                                        {type.name}
                                    </MenuItem>
                                );
                            })}
                        </BSCSelect>
                    </div>
                </div> */}
                {/* {useCustomDateRange && ( */}
                {/* <div className={classes.rangePicker}>
                    <BSCDateRangePicker
                        startPickerProps={startProps}
                        endPickerProps={endProps}
                        startGridProps={gridProps}
                        endGridProps={gridProps}
                        gridContainerProps={gridContainerProps}
                        toFromHidded={{mdDown: true}}
                    />
                </div> */}
                {/* )} */}
                <div className={classes.buttons}>
                    {/* {useCustomDateRange && ( useCustomDateRange ?  :classes.buttonsNoDatePicker */}
                    {/* <BSCButton color="primary" onClick={() => onCustomDateRangeExecute(formState)}>
                        <BSCTypography label="Get Report" capitalize />
                    </BSCButton> */}
                    {/* )} */}
                    {/* <Hidden mdDown>
                        <Box ml={5}>
                            <BSCButton color="secondary" onClick={onPrint}>
                                <BSCTypography label="print" capitalize />
                            </BSCButton>
                        </Box>
                    </Hidden> */}
                </div>
            </div>
        </>
    );
};

export default SalesSummaryHeader;
