/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import CurrentRating from './components/currentRating';
import FeedbackRankings from './components/feedbackRankings';
import FeedbackListHeader from './components/feedbackListHeader';
import FeedbackList from './components/feedbackList';
import {useMsal} from '@azure/msal-react';
import {useAuthFunctions, useAuthState} from '../../../../../../AuthContext';
import {AccountApi} from '../../../../../../client/openapitools/seller/api';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {Configuration} from '../../../../../../client/openapitools/seller/configuration';
import {AUTH_REQUESTS} from '../../../../../../authConfig';
import useReduxToast from '../../../../../../features/hooks/redux/toast/useReduxToast';
import {useLocation} from 'react-router-dom';

const Feedback = () => {
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const filterState = {
        //was using use state when the control was wired up
        positive: true,
        neutral: true,
        negative: true,
    };
    const location = useLocation();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    let sellerAccountApi = new AccountApi(apiAuthConfig);
    // const {data: sellerFeedback, loading: sellerFeedbackLoading} = useQuery(sellerAccountApi.getSellerFeedback, []);
    const [sellerFeedback, setSellerFeedback] = useState(null);
    const [sellerFeedbackLoading, setSellerFeedbackLoading] = useState(true);

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'pageview',
            page: {
                path: location.pathname,
                search: location.search,
                hash: location.hash,
            },
        });
    }, [location]);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            sellerAccountApi = new AccountApi(apiConfig);
                            const response = await sellerAccountApi.getSellerFeedback();
                            setSellerFeedbackLoading(false);
                            setSellerFeedback(response);
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Error fetching seller feedback.',
                                contextKey: 'Feedback',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    console.log(error);
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <CurrentRating
                    rating={sellerFeedback?.data.rating}
                    comments={sellerFeedback?.data.feedback.map(fb => fb.comment)}
                    loading={sellerFeedbackLoading}
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <FeedbackRankings rankings={sellerFeedback?.data.rankings} loading={sellerFeedbackLoading} filters={filterState} />
            </Grid>
            <Grid item xs={12}>
                <FeedbackListHeader
                    page={page}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    feedbackSize={sellerFeedback?.data?.feedback?.length}
                />
                <FeedbackList
                    page={page}
                    sizePerPage={perPage}
                    feedbackList={sellerFeedback?.data.feedback}
                    loading={sellerFeedbackLoading}
                    onPageChange={setPage}
                />
            </Grid>
        </Grid>
    );
};

export default Feedback;
