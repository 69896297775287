/* eslint-disable */
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AUTH_REQUESTS} from '../../authConfig';
import {useAuthFunctions, useAuthState} from '../../AuthContext';
import {UserApi} from '../../client/openapitools/seller/api';
import {Configuration} from '../../client/openapitools/seller/configuration';
import BSCLoading from '../../features/common/bscLoading/bscLoading';
import useReduxToast from '../../features/hooks/redux/toast/useReduxToast';
import SellersLockerHeader from '../../features/navigation/sellersLocker/SellersLockerHeader';
import {RootState} from '../../redux/reducers';
import * as constants from '../../shared/constants';
import SellerLockerRoutes from './routes';

function SellersLocker() {
    const dispatch = useDispatch();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let userApi = new UserApi(apiAuthConfig);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const [userState, setUserState] = useState(null);
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const sellerState = useSelector((state: RootState) => state.seller);
    useEffect(() => {
        document.title = 'BuySportsCards.com: Millions of Sports Cards for Sale';
    }, []);

    const [hasPWAOrigin, setHasPWAOrigin] = useState(false);
    const [isSignupInProcess, setIsSignupInProcess] = useState(false);
    useEffect(() => {
        if (sellerState.hasVerifiedMicroDeposits) {
            setIsSignupInProcess(false);
        } else {
            if (userState) {
                if (userState?.roleId !== '2' || userState?.sellerProfile?.applicationStatus !== 'completed') {
                    setIsSignupInProcess(true);
                } else {
                    setIsSignupInProcess(false);
                }
            } else {
                if (marketplaceState && marketplaceState.pwaSettings.pwaOS !== null) {
                    setHasPWAOrigin(true);
                }
            }
        }
    }, [marketplaceState, sellerState, userState]);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            userApi = new UserApi(apiConfig);
                            const userResponse = await userApi.getSellerUserProfile();
                            setUserState(userResponse.data);
                            dispatch({
                                type: constants.SET_SELLERS_LOCKER_USER,
                                payload: {sellersLockerUser: userResponse.data},
                            });
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred.',
                                contextKey: "Seller's Locker",
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    return (
        <>
            {!hasPWAOrigin && (
                <>
                    {userState === null && <BSCLoading loading={true} />}
                    {userState !== null && (
                        <>
                            {!isSignupInProcess && <SellersLockerHeader />}
                            <SellerLockerRoutes />
                        </>
                    )}
                </>
            )}
            {hasPWAOrigin && (
                <>
                    <SellersLockerHeader />
                    <SellerLockerRoutes />
                </>
            )}
        </>
    );
}

export default SellersLocker;
